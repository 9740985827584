import {ItemEditor, ItemEditorColumn, ItemEditorSection} from 'elements/itemeditor';
import {ItemEditorInputField, ItemEditorBooleanField, ItemEditorUploadField, ItemEditorRichTextField, ItemEditorListEditorField} from 'elements/itemeditor/field';
import {Column, PriceColumn} from 'elements/itemtable/column';
import {SelectedProduct} from 'elements/listeditor/selecteditem';
import {InputField, TextAreaField} from 'form';

export class Kit extends ItemEditor {
	public readonly webService = 'kits';
	protected readonly itemType = 'Tasting Kit';
	protected readonly responseParam = 'kit';
	protected readonly defaults = {kit: {sortOrder: 0}};
	protected readonly cssClass = 'wrap';

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Tasting Kit Details', [
				new ItemEditorInputField('name', 'Name', true),
				new ItemEditorInputField('subtitle', 'Subtitle'),
				new ItemEditorInputField('sortOrder', 'Display Order', false, InputField),
				new ItemEditorBooleanField('isDisabled', 'Enabled', true, true)
			]),
			new ItemEditorSection('Image', [
				new ItemEditorUploadField('image', '')
			])
		], 'narrow'),
		new ItemEditorColumn([
			new ItemEditorSection('Products', [
				new ItemEditorListEditorField('products', '', 'products', {
					tableConfig: {
						columns: [
							'Name',
							new Column('Vintage', 'year'),
							new PriceColumn('Price', 'price.retail')
						],
						search: {
							columns: ['name']
						}
					},
					showFooter: true,
					ThisSelectedItem: SelectedProduct
				})
			], 'blocks')
		], 'wide'),
		new ItemEditorColumn([
			new ItemEditorSection('Description', [
				new ItemEditorInputField('description', '', true, TextAreaField),
			], 'blocks')
		], 'full')
	];
}
