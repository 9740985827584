import {ItemEditorSection, ItemEditorColumn, ItemCloneConfig, ItemEditor, ItemEditorContainer} from 'elements/itemeditor';
import {ItemEditorInputField, ItemEditorBooleanField, ItemEditorDropdownField, ItemEditorDropdownOption, ItemEditorPriceField, ItemEditorUploadField, ItemEditorListEditorField} from 'elements/itemeditor/field';
import {WebService, WebServiceResponse} from 'webservice';
import {InputField} from 'form';
import {Column} from 'elements/itemtable/column';
import {SelectedProduct} from 'elements/listeditor/selecteditem';

export class Product extends ItemEditor {
	public readonly webService = 'products';
	protected readonly responseParam = 'product';
	protected readonly defaults = () => WebService.post('products/details').then((data) => Object.assign(data, {product: {}}));

	protected readonly cloneConfig = new ItemCloneConfig({
		label: 'New Vintage',
		onClone: () => {
			const vintage = this.getField('year').field;
			vintage.value = '';
			vintage.element.focus();
		},
		onSave: (saveData) => {
			saveData.isNewVintageOf = this.originalId;
			return true;
		}
	});

	protected readonly columns = [
		new ItemEditorColumn([
			new ItemEditorSection('Product Details', [
				new ItemEditorInputField('name', 'Name', true),
				new ItemEditorInputField('bin', 'Bin #', false, InputField),
				new ItemEditorInputField('upc', 'UPC', false, InputField),
				new ItemEditorInputField('year', 'Vintage', false, InputField),
				new ItemEditorInputField('grape', 'Grape(s)'),
				new ItemEditorDropdownField('productType', 'Type', ['Wine', 'Beer', 'Liquor'], true),
				new ItemEditorDropdownField('packaging', 'Packaging', ['Bottle', 'Can', 'Pack'], true),
				new ItemEditorDropdownField('color', 'Color', ['Red', 'White', 'Pink', 'Orange'], false, true),
				new ItemEditorDropdownField('carbonation', 'Carbonation', ['Still', 'Sparkling'], false, true),
				new ItemEditorDropdownField('body', 'Body', ['Light', 'Medium', 'Full'], false, true),
				new ItemEditorInputField('drinkBy', 'Drink By', false, InputField),
				new ItemEditorInputField('idealTemperature', 'Ideal Temp', false, InputField),
				new ItemEditorDropdownField('glassware', 'Glass', [
					new ItemEditorDropdownOption('burgundy', 'Burgundy'),
					new ItemEditorDropdownOption('bordeaux', 'Bordeaux'),
					new ItemEditorDropdownOption('sauv+bordeaux', 'Sauvignon Blanc or Bordeaux')
				], false, true),
				new ItemEditorInputField('video', 'Video ID', false, InputField, 'Paste YouTube ID or URL'),
				new ItemEditorBooleanField('isDisabled', 'Available', true, true),
				new ItemEditorBooleanField('isFeatured', 'Featured'),
				new ItemEditorBooleanField('isPublic', 'Public')
			]),
			new ItemEditorSection('Pricing', [
				new ItemEditorPriceField('price.retail', 'Retail'),
				new ItemEditorPriceField('price.dist', 'Distributor')
			]),
			new ItemEditorSection('Origin', [
				new ItemEditorDropdownField('origin.winery', 'Winery', undefined, true, true, true),
				new ItemEditorInputField('origin.vineyard', 'Vineyard'),
				new ItemEditorInputField('origin.subregion', 'Subregion'),
				new ItemEditorInputField('origin.region', 'Region'),
				new ItemEditorInputField('origin.distributor', 'Distributor')
			])
		], 'narrow'),
		new ItemEditorColumn([
			new ItemEditorContainer([
				new ItemEditorSection('Photo', [
					new ItemEditorUploadField('image', '')
				])
			], 'narrow'),
			new ItemEditorSection('Description', [
				new ItemEditorInputField('summary', 'Short Summary'),
				new ItemEditorInputField('description', 'Full Description'),
				new ItemEditorInputField('pairings', 'Pairings')
			], 'blocks'),
			new ItemEditorContainer([
				new ItemEditorSection('Recommendations', [
					new ItemEditorListEditorField('recommendations', '', 'products', {
						tableConfig: {
							columns: [
								'Name',
								new Column('Vintage', 'year')
							],
							search: {
								columns: ['name']
							},
							hideDisabled: true
						},
						ThisSelectedItem: SelectedProduct
					})
				], 'blocks')
			], 'full')
		], 'wide wrap')
	];
	
	protected loaded(response: WebServiceResponse): void {
		(this.getField('origin.winery') as ItemEditorDropdownField).setOptions(
			response.wineries.filter((winery) => winery && winery._id && winery.name).map((winery) => new ItemEditorDropdownOption(winery._id, winery.name))
		);
		super.loaded(response);
		if (!response.product) return;
		const upload = this.getField('upload') as ItemEditorUploadField;
		const color = this.getField('color');
		if (!response.product.image) color.onChange = upload.onChange = () => {
			if (!color.field || !color.field.value || !upload.field) return;
			const {image} = upload.field;
			if (!image || /^\/assets\/generic-bottles\//.test(image)) {
				upload.field.image = `/assets/generic-bottles/${color.field.value.toLowerCase()}`;
			}
		};
	}
}
